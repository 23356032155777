export const LOGIN = "/"
export const DASHBOARD = "/dashboard"
export const NON_REVARC_DASHBOARD = "/file-monitoring"

export const AUDIT = "/audit"



export const ENCOUNTER_FILES = "/claim-files"
export const IMPORT_ENCOUNTER_FILES = "/import-encounter-files"
export const ENCOUNTERS = "/encounters"
export const ENCOUNTER_HTML_VIEW = "/claim-view"
export const ENCOUNTER_VIEW_BASED_ON_FILE = "/claims"



export const PAYMENTS_FILES = "/remittances"
export const IMPORT_PAYMENTS_FILES = "/import-remittances"
export const PAYMENTS = "/payments"
export const PAYMENT_HTML_VIEW = "/payment-view"

export const PAYMENT_VIEW_BASED_ON_FILE = "/remittances-payments"


export const PAYMENT_835_LITE = "/payment-835-lite"
export const PAYMENT_835_LITE_VIEW_URL = "/payment-835-lite-view"

export const PATIENT_PAY_URL = "/patient-pay"
export const PATIENT_PAY_VIEW_URL = "/patient-pay-view"


export const CORRESPONDANCE_URL = "/correspondence"
export const CORRESPONDANCE_VIEW_URL = "/correspondence-view"


export const LOCKBOX_URL = "/lockbox"




export const REASSOCIATION_ORPHAN = "/orphan-payments"
export const REASSOCIATION_MATCHED_REMITTANCES = "/matched-remittances"
export const REASSOCIATION_UNMATCHED_REMITTANCES = "/unmatched-remittances"



export const REASSOCIATION_REPORTS = "/reports"





export const USER_MANAGEMENT_ROLES = "/roles"
export const USER_MANAGEMENT_CREATE_ROLES = "/create-role"
export const USER_MANAGEMENT_UPDATE_ROLES = "/update-role"

export const USER_MANAGEMENT_GROUPS = "/groups"
export const USER_MANAGEMENT_CREATE_GROUPS = "/create-group"
export const USER_MANAGEMENT_UPDATE_GROUPS = "/update-group"


export const USER_MANAGEMENT_USERS = "/users"
export const USER_MANAGEMENT_USER_LOGS = "/user-logs"
export const USER_MANAGEMENT_CREATE_USERS = "/create-user"
export const USER_MANAGEMENT_UPDATE_USERS = "/update-user"


export const CLIENT_MANAGEMENT_CLIENTS = "/clients"


export const PROFILES = "/profile"
export const TWO_FACTOR_AUTHENTICATION = "/two-factor-authentication"
export const USER_TYPE = "/user-type"
export const CUSTOM_ERROR_PAGE_URL = "/error-page"




export const NACHA_FILES = "/nacha-files"
export const NACHA_PAYMENTS = "/nacha-payments"
export const IMPORT_NACHA_FILES = "/import-nacha-files"



export const BAI_FILES = "/bai-files"
export const BAI_PAYMENTS = "/bai-transactions"
export const IMPORT_BAI_FILES = "/import-bai-files"




export const OUTPUT_FILES = "/output-files"
export const AUDIT_LOG = "/audit-log"
export const SETTINGS = "/settings"


export const CAPTCHA = "/captcha"



export const SEARCH_PAGE = "/search"






